import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dontleaveme',
  templateUrl: './dontleaveme.component.html',
  styleUrls: ['./dontleaveme.component.scss']
})
export class DontleavemeComponent implements OnInit {

  name:string = "DON'T LEAVE ME";
  spotifyURL:string = "https://open.spotify.com/track/08F6JH2R3GRIiDCjuRYky2?si=c1cb818a24b44f2f";
  applemusicURL:string = "https://music.apple.com/at/album/dont-leave-me-single/1558834402?at=1l3v9Tx&uo=4&app=itunes";
  amazonmusicURL:string = "https://music.amazon.de/albums/B08ZB4TJNX?marketplaceId=A1PA6795UKMFR9&musicTerritory=DE";
  youtubeURL:string = "https://www.youtube.com/watch?v=NKWR7XPi2mc";
  photo:string = "../assets/dontleaveme.jpg";
  spotifyid:string = '08F6JH2R3GRIiDCjuRYky2';


  constructor() { }

  ngOnInit(): void {
  }

}
