<app-header [title]='title' [photo]='photo'></app-header>

1<div class="wrapper">

    <p class="title"> Maximilian Höller <br>
        Am Tobersbach 8, 4221 Steyregg, Austria <br>
        +43 650 4422147 <br>
        max@onelastglance.com</p>
</div>

<app-bottom></app-bottom>
