import { Meta, Title } from '@angular/platform-browser';
import { slide } from './../animation';
import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
  animations: [slide]
})
export class AboutComponent implements OnInit {

  constructor(private sideTitle: Title, private meta: Meta) { }

  ngOnInit(): void {
    this.sideTitle.setTitle('One Last GLance - About');

    this.meta.updateTag({
      name: 'description',
      content: 'One Last Glance is a band which was founded in Linz in 2016. Their music is heavily influenced by grunge andmerges perfectly with metal, punk and rock. Guitars, bass and drums create a heavy sound that is topped by not only massive but also very melodic and charming vocals. The five young musicians have already shared stages with austrian topacts such as Kaiser Franz Josef and Krautschädl.'
    })

  }
  title:string = 'ABOUT';
  photo:string = "./../../assets/bandpic.jpg";
  photoMobile:string = "./../../assets/bandpic_squared.jpg";
}
