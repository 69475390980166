<div class="about">



    <app-header class="defaultHeader" [photo]="photo" [title]="title">
    </app-header>
  <app-header class="mobileHeader" [photo]="photoMobile" [title]="title">
  </app-header>

    <div class="wrapper">
        <h1 class="subtitle is-3">One Last Glance is a band which was founded in Linz in 2016. Their music is
            heavily
            influenced by
            grunge and
            merges perfectly with metal, punk and rock. Guitars, bass and drums create a heavy sound that is topped by
            not
            only massive but also very melodic and charming vocals. The five young musicians have already shared stages
            with
            austrian topacts such as Kaiser Franz Josef and Krautschädl.</h1>
        <hr>

        <h1 class="subtitle is-3" id="numbertwo">One Last Glance ist eine 2016 in Linz gegründete Band. Ihre stark von
            Grunge
            beeinflusste
            Musik vereinigt sich in spannendster Weise mit Metal, Punk und Rock. Der durch Gitarren, Bass und Schlagzeug
            geprägte Sound ist heavy und wird von manchmal hartem, oft aber auch sehr melodiösem Gesang abgerundet. Die
            fünf
            jungen Musiker teilten sich bereits Bühnen mit Acts wie Kaiser Franz Josef und Krautschädl.</h1>

    </div>
</div>
<app-bottom></app-bottom>
