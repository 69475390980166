import { Title } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {

  constructor(private sideTitle: Title) { }

  ngOnInit(): void {

    this.sideTitle.setTitle('One Last Glance - Gallery');
  }

  title:string = 'GALLERY'
  photo:string = "./../../assets/bass1a.jpg";

  images: string[] = ["./../../assets/maxsquare.jpg",
    "./../../assets/felixsquare.jpg", "./../../assets/simonsquare.jpg", "./../../assets/davidsquare.jpg"]

  copyright: string[] = ["shot by Edwin Enzlmüller", "shot by Edwin Enzlmüller", "shot by Edwin Enzlmüller", "shot by Edwin Enzlmüller"];
  private = "private picture";
  imagesVacation: string[] = ["./../../assets/bandvacation/8.jpg", "./../../assets/bandvacation/7.jpg", "./../../assets/bandvacation/6.jpg", "./../../assets/bandvacation/5.jpg",
    "./../../assets/bandvacation/4.jpg",
    "./../../assets/bandvacation/3.jpg",
    "./../../assets/bandvacation/2.jpg",
    "./../../assets/bandvacation/1.jpg",]

  imagesStudio: string[] = ["./../../assets/studio/4.jpg", "./../../assets/studio/3.jpg", "./../../assets/studio/2.jpg", "./../../assets/studio/1.jpg",]
}
