import { animate, query, stagger, state, style, transition, trigger, keyframes } from '@angular/animations';


export let fade = trigger('fade', [state('void', style({ opacity: 0 })),
transition('void => *', [animate(3000)]),


]);

export let blur = trigger('blur', [state('void', style({ width: '100px' })),
transition('void => *', [animate(3000)]),


]);

export let slide = trigger('slide', [state('void', style({ margin: '100px' })),
transition('void => *', [animate(1000)]),


]);

export let listAnimation = trigger('listAnimation', [

    transition('* => *', [

        query(':enter', style({ opacity: 0 }), { optional: true }),

        query(':enter', stagger('100ms', [
            animate('2s ease-in', keyframes([
                style({ opacity: 0, transform: 'translateY(-75px)', offset: 0 }),
                style({ opacity: 0.5, transform: 'translateY(35px)', offset: 0.3 }),
                style({ opacity: 1, transform: 'translateY(0px)', offset: 1 })



            ]))
        ]))
    ])

])