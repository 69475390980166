import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gasoline',
  templateUrl: './gasoline.component.html',
  styleUrls: ['./gasoline.component.scss']
})
export class GasolineComponent implements OnInit {

  name:string = "Gasoline"
  photo = "../assets/gasoline.jpg";

  //  public photo = "..\assets\gasoline.jpg";
  spotifyURL:string = "https://open.spotify.com/track/2QY6g3XO4VNbEX7g2VXo7V?si=d5a858a443e7412e";
  applemusicURL:string = "https://music.apple.com/at/album/gasoline/1569382571?i=1569382583";
  amazonmusicURL:string = "https://music.amazon.de/albums/B095X7VDQK?do=play&ref=dm_ws_dp_ald_bb_phfa_xx_xx_xx";
  spotifyid:string = "2QY6g3XO4VNbEX7g2VXo7V";
  youtubeURL:string = "https://www.youtube.com/watch?v=nvjCJCL3mJE";
  teaserURL:string = "";

  constructor() { }

  ngOnInit(): void {
  }

}
