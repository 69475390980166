import { Component } from '@angular/core';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'OneLastGlance';


  ngOnInit(): void {

    let cc = window as any;
    cc.cookieconsent.initialise({
      palette: {
        popup: {
          background: "#000000"
        },
        button: {
          background: "000000",
          text: "#ffffff"
        }
      },
      theme: "classic",
      content: {
        message: "This website uses both our own and third party cookies to enhance your browsing experience. By continuing to use this website or by closing this box you are indicating your consent to our use of those cookies." +
          " For more information click",
        dismiss: "OKAY",
        link: " here.",
        href: "../privacy"
      }
    });
  }

}
