//import { AngularFireStorageModule, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/storage';
//import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { HttpClientModule, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, observable } from 'rxjs';


var Spotify = require('spotify-web-api-js');

var scopes = ['user-read-private', 'user-read-email'];
var redirectUri = 'localhost:4200/save';
var clientId = "84a143c4a85c4e36a8034ddaa8ccd996";
var clientSecret = 'halt stop'
var state = 'some-state-of-my-choice';
var token: String;
var s = new Spotify;


var returnString = "";


@Injectable({
  providedIn: 'root'
})
export class SpotifyauthService {

  // notes_firebase_data: AngularFireList<any> | undefined;
  // notes_angular: Observable<any[]> | undefined;


  public loginUrl = "";
  public username = "";
  public showSignIn: Boolean = false;
  public access_token: String = "";
  constructor(private router: ActivatedRoute, private http: HttpClient,) { }
  public getUrl(redirect: string) {

    var authUrl = "https://accounts.spotify.com/authorize?client_id=";
    authUrl += clientId;
    authUrl += '&response_type=code&redirect_uri='
    authUrl += encodeURI(redirect);
    authUrl += "&show_dialog=true";
    authUrl += "&scope=user-read-recently-played user-top-read user-library-modify user-follow-modify"

    return authUrl;

  }

  /* upload(user: any) {
     let refreshtoken = localStorage.getItem('refresh_token');
     let accesstoken = localStorage.getItem('access_token');
     let username = user;
     this.notes_firebase_data = this.db.list('Users');
     this.notes_angular = this.notes_firebase_data.valueChanges();
     this.notes_firebase_data.push({
       refreshtoken,
       accesstoken,
       username

     })
   }
 */
  fetchToken(token: string, redirect: string) {


    let body = "grant_type=authorization_code";
    body += "&code=" + token;
    body += "&redirect_uri=" + encodeURI(redirect);
    body += "&client_id=" + clientId;
    body += "&client_secret=" + clientSecret;
    this.getToken(body);


  }

  getToken(body: string) {


    var xhr = new XMLHttpRequest;
    /*this.client.post('https://accounts.spotify.com/api/token', body, {
      headers: new HttpHeaders().set('Content-Type', "application/x-www-form-urlencoded").set('Authorizatuion', "Basic " + btoa(clientId + ':' + clientSecret))
    }).subscribe(data => { console.log(data); });
    */
    var xhr = new XMLHttpRequest;
    xhr.open('POST', 'https://accounts.spotify.com/api/token', true);
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhr.setRequestHeader("Authorization", "Basic " + btoa(clientId + ':' + clientSecret))
    xhr.send(body);


    //  console.log(body)
    xhr.onload = function () {
      let obj = JSON.parse(this.responseText);

      localStorage.setItem('access_token', obj.access_token);
      localStorage.setItem('refresh_token', obj.refresh_token);


    }




  }

  refreshToken(token: any) {
    var refresh_token_send = token;
    let body = "grant_type=refresh_token";
    body += "&refresh_token=" + refresh_token_send;
    body += "&client_id=" + clientId;
    body += "&client_secret=" + clientSecret;


    this.http.post<Access>('https://accounts.spotify.com/api/token', body, {
      headers: new HttpHeaders().set("Content-Type", "application/x-www-form-urlencoded")
    }
    ).subscribe(response => {
      localStorage.setItem('access_token', response.access_token)
      return response.access_token;
      //this.upload(new User(localStorage.getItem('refresh_token'), response.access_token));


    });

  }

  getUsersPlaylists() {

    this.http.get('https://api.spotify.com/v1/me', {
      headers: new HttpHeaders().set('Authorization', localStorage.getItem('access_token') || "")
    })
      .subscribe(response => {

      })

  }
  getMe(): string {


    s.setAccessToken(localStorage.getItem('access_token'));
    return s.getMe((err: any, data: any) => {
      if (err) console.error(err);

      else {
        //this.upload(data.display_name)

      }

    }


    )
  }
  saveSong(trackId: String) {

    s.setAccessToken(localStorage.getItem('access_token'));

    s.addToMySavedTracks([trackId], (err: any, data: any) => {
      if (err) console.error(err);

      else {
        return;

      }
    }
    );
  }
  followUs() {
    s.setAccessToken(localStorage.getItem('access_token'));

    s.followArtists(['5A3zjBmNmFCXZbodflKczR'], (err: any, data: any) => {
      if (err) console.error(err);

      else {
        return;

      }
    }
    );
  }
  getPopularityIndex(songIds: string[]) {
    let songs: string[] = songIds;

    s.setAccessToken(localStorage.getItem('access_token'));


    s.getTracks(songIds, (err: any, data: any) => {
      if (err) console.error(err);

      else {
        console.log(data)
        for (var i = 0; i < songIds.length; i++) {
          songs[i] = data.tracks[i].name + " - " + data.tracks[i].artists[0].name + " " + data.tracks[i].popularity;

        }
      }
    }
    )
    return songs;


  }

  getSong(id: string):Observable<any> {
    return this.http.get("https://presave.onelastglance.com/spotify/song?id=" + id);
  }
}









export interface Access {
  access_token: string;
  refresh_token: string;
}

export interface Playlist {
  name: String;
  id: String;

}
class User {
  username: String;
  access_token: String;
  refresh_token: String;

  constructor(refresh_token: any, access_token: any, username: any) {
    this.access_token = access_token;
    this.refresh_token = refresh_token;
    this.username = username;

  }



}

