<app-header class="defaultHeader" [photo]="photo" [title]="title">
</app-header>
<app-header class="mobileHeader" [photo]="photoMobile" [title]="title">
</app-header>
<div class="wrapper">

  <h1 id="title" class="subtitle is-2">One Last Glance & Perfumed Garden - Live at Kramladen</h1>
  <h1 id="subtitle" class="subtitle is-3">Bitte Ticketanzahl eingeben und Zahlungsart wählen. Für den Einlass beim Konzert die Zahlungsbestätigung mitbringen.</h1>

  <div class="inputWrapper">
  <input min="0" max="10" [(ngModel)]="numberOfTickets" (ngModelChange)="calcPrice()" class="input is-small" type="number"
         placeholder="Number of tickets">
  </div>

  <h1 class="subtitle is-3">Zu zahlender Betrag: € {{price}},- </h1>

  <div [@fade] class="buttons">
    <a target="_blank" href="https://www.paypal.com/paypalme/onelastglance/{{price}}">
      <button (click)="unclickTransfer()" class="button is-large is-outlined is-bold">
                <span class="icon">
                    <i class="fa fa-paypal"></i>
                </span>
            Paypal
      </button>
    </a>

    <button (click)="clickTransfer()" class="button is-large is-outlined is-bold">
                <span class="icon">
                    <i class="fa fa-money"></i>
                </span>
      Überweisung
    </button>

    <h1 *ngIf="numberOfTickets > 0 && transferClicked" class="subtitle is-3">Bitte überweise € {{price}},- an folendes Konto: <br> AT45 3477 7000 0573 9719</h1>

  </div>

</div>

<app-bottom></app-bottom>
