<div class="bottom">
    <div class="buttons">
        <a target="_blank" href="https://www.facebook.com/one.last.glance">
            <button class="button is-large is-outlined is-bold">
                <span class="icon">
                    <i class="fa fa-facebook fa-lg"></i>
                </span>
            </button>
        </a>
        <a target="_blank" href="https://www.instagram.com/one.last.glance">
            <button class="button is-large is-outlined">
                <span class="icon">
                    <i class="fa fa-instagram fa-lg"></i>
                </span>
            </button>
        </a>
        <a target="_blank" href="https://www.youtube.com/onelastglance">
            <button class="button is-large is-outlined">
                <span class="icon">
                    <i class="fa fa-youtube fa-lg"></i>
                </span>
            </button>
        </a>
    </div>
    <div class="navigation">
        <a href="../imprint/">
            <p class="subtitle is-4" href="../imprint/">IMPRINT </p>
        </a>
        <a href="../privacy">
            <p class="subtitle is-4" href="../imprint/">PRIVACY </p>
        </a>
    </div>
</div>
