import { Title } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-imprint',
  templateUrl: './imprint.component.html',
  styleUrls: ['./imprint.component.scss']
})
export class ImprintComponent implements OnInit {



  constructor(private sideTitle: Title) { }

  ngOnInit(): void {

    this.sideTitle.setTitle('One Last Glance - Imprint')
  }
  title:string = "IMPRINT";
  photo:string = "./../../assets/snare1d.jpg";

}
