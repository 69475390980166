import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pbh',
  templateUrl: './pbh.component.html',
  styleUrls: ['./pbh.component.scss']
})
export class PbhComponent implements OnInit {

  title: string = '';
  name: string = 'PUSH BACK HARDER';

  photo: string = './../../assets/pbh-min.jpg';
  spotifyURL: string = 'https://open.spotify.com/track/24qTrRY9h6mlN0i1aV8ezx?si=0b0f355c6d72468a';
  youtubeURL: string = 'https://youtu.be/3tA3V8POnnk';
  applemusicURL: string = 'https://geo.music.apple.com/album/push-back-harder/1661329328?i=1661329329&app=music';
  amazonmusicURL: string = '';
  spotifyid: string = '24qTrRY9h6mlN0i1aV8ezx';
  teaserPath: string = './../../assets/pbh-teaser.mp4'

  constructor() { }

  ngOnInit(): void {
  }

}
