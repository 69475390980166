import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bleib-in-der-schule',
  templateUrl: './bleib-in-der-schule.html',
  styleUrls: ['./bleib-in-der-schule.scss']
})
export class BleibInDerSchule implements OnInit {

  title: string = '';
  name: string = 'BLEIB IN DER SCHULE';

  photo: string = './../../assets/bleib-in-der-schule.png';
  spotifyURL: string = 'https://open.spotify.com/track/5MidpPrdWYfnBLduhf3HKF?si=725cacc4aae74c16';
  youtubeURL: string = 'https://www.youtube.com/watch?v=_oyTl_mlu2g&ab_channel=OneLastGlance';
  applemusicURL: string = 'https://music.apple.com/us/album/bleib-in-der-schule/1631908761?i=1631908763';
  amazonmusicURL: string = 'https://music.amazon.de/albums/B0B57VQMMT?marketplaceId=A1PA6795UKMFR9&musicTerritory=DE&ref=dm_sh_q7uyqJegqcMK0Tcr7m5qlQJfi';
  spotifyid: string = '5MidpPrdWYfnBLduhf3HKF';
  teaserPath: string = "./../../assets/teaser.mp4"

  constructor() { }

  ngOnInit(): void {
  }

}
