import { Title } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.scss']
})
export class BookingComponent implements OnInit {

  constructor(private sideTitle: Title) { }

  ngOnInit(): void {

    this.sideTitle.setTitle('One Last Glance - Booking');
  }

  title:string = "BOOKING";
  photo:string = "./../../assets/koffer1a.jpg";
}
