<div @blur @fade id="einBild"><span>
        <a target="_blank" href={{link}}>
            <img [src]=image>
        </a>
        <div id="copyright">

            <p>{{copyright}}</p>
        </div>
    </span>

</div>