<div class="header">
    <app-header [photo]='artwork'></app-header>
</div>
<div class="wrapper">



    <p class="title is-1">STREAM "{{name}}" NOW!</p>

    <div [@fade] class="buttons">
        <a target="_blank" href="{{spotifyURL}}">
            <button *ngIf='spotifyURL != ""' class="button is-large is-outlined is-bold">
                <span class="icon">
                    <i class="fa fa-spotify fa-5x"></i>
                </span>
            </button>
        </a>
        <a target="_blank" href="{{applemusicURL}}">
            <button *ngIf='applemusicURL != ""' class="button is-large is-outlined">
                <span class="icon">
                    <i class="fa fa-apple fa-5x"></i>
                </span>
            </button>
        </a>
        <a target="_blank" href="{{youtubeURL}}">
            <button *ngIf='youtubeURL != ""' class="button is-large is-outlined">
                <span class="icon">
                    <i class="fa fa-youtube fa-5x"></i>
                </span>
            </button>
        </a>
        <a target="_blank" href="{{amazonmusicURL}}">
            <button *ngIf='amazonmusicURL != ""' class="button is-large is-outlined">
                <span class="icon">
                    <i class="fa fa-amazon fa-5x"></i>
                </span>
            </button>
        </a>





    </div>

  <p *ngIf='teaserPath != ""'  class="title is-1">DOWNLOAD TEASER TO PROMOTE US ON INSTAGRAM</p>


  <div *ngIf='teaserPath != ""' [@fade] class="buttons">


    <a download="teaser" target="_blank" href="{{teaserPath}}">
      <button *ngIf='teaserPath != ""' class="button is-large is-outlined">
                <span class="icon">
                    <i class="fa fa-download fa-5x"></i>
                </span>
      </button>
    </a>




  </div>


    <div [@fade] class="artwork">
        <img width="1250" height="1250" [src]=artwork>
    </div>

    <div>

        <p class="title is-1"> ABOUT THE SONG</p>
        <p class="title is-2"> ENERGY {{energy}}/100</p>
        <p class="title is-2"> DANCEABILITY {{dancability}}/100</p>
        <p class="title is-2"> DURATION {{length}} </p>
        <p class="title is-2"> TEMPO {{tempo}} BPM</p>


    </div>

</div>
