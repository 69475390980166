import { SpotifyauthService } from './../spotifyauth.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-savesong',
  templateUrl: './savesong.component.html',
  styleUrls: ['./savesong.component.scss']
})
export class SavesongComponent implements OnInit {

  constructor(private auth: SpotifyauthService, private router: ActivatedRoute) { }


  photo = "./../../assets/git1b.jpg"
  title = "THANK YOU"



  ngOnInit(): void {
    this.router.queryParams.subscribe(params => {
      console.log(params);

      let code = params.code;
      let songid = params.song;

      this.auth.fetchToken(code, 'https://onelastglance.com/save?song=' + songid);


      //this.auth.getMe();
      this.auth.saveSong(songid);
      this.auth.followUs();

    });

  }

}
