import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser'

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  constructor(private title: Title, private meta: Meta) { }

  ngOnInit(): void {

    this.title.setTitle('One Last Glance - Landing');
    this.meta.updateTag(
      {
        name: 'keyword',
        content: 'linz rockmusic, felix larcher, starmania, grunge, metal, one last glance, glance music'
      });

    this.meta.updateTag({

      name: 'description',
      content: 'Finest metal from austria combined with melodious vocals and heavy guitars!'

    })

  }

}
