import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SpotifyauthService } from '../spotifyauth.service';


var Spotify = require('spotify-web-api-js');

var scopes = ['user-read-private', 'user-read-email'];
var redirectUri = 'https://onelastglance.com/popularity/';
var clientId = "84a143c4a85c4e36a8034ddaa8ccd996";
var clientSecret = '5909715f868642bfb6fff38783977153'
var state = 'some-state-of-my-choice';
var token: String;
var s = new Spotify;
var access_token = "";



@Component({
  selector: 'app-popularity',
  templateUrl: './popularity.component.html',
  styleUrls: ['./popularity.component.scss']
})
export class PopularityComponent implements OnInit {

  public showItems: String[] = [];
  public songsIds: string[] = ['5Pxoy3belMPeGlt3J1kK2V','36x4wbJVcmp3y76HCDTQOn','7yDbKSKle6OwUEUOVl0J6b','733Ne0QR9x2pkGXSInzkcv','5WJOPTxnF9U0pbW6JAFezY','1bq1B6g3lQxBZL3ufbEL1y', '2qcMTYLticZO6pelbEFAwr', '1nB5vtmmyv6xDb8mBktv8m', '2QY6g3XO4VNbEX7g2VXo7V', '2dPSEEPmvbjU6OFKXYqSLF', '0sRl57W6KnlZYOtx13oaK3', '5LPBounZDzSsUqECtZBn2t', '6rCgLtc3IjXGbge1iiE0qW', '3BT8Tk446MWoKxRQ5X7ruA', '7lgP3SuahqEEgAse7MATvl', '08F6JH2R3GRIiDCjuRYky2', '2QFHsVTEIKco0JJAyhRur9', '6XryjQQehFsTEQM5hYdbUE', '7B3Fb4tubPdaKW7fsIKijE', '0WJpO9gZ6G0r57WD4IPxSP', '3jhSAB1E4yvQJCgbM8hjMx'];
  public showSignIn: Boolean = true;
  public loginUrl = "";
  public songs: String[] = [];
  public code: string = "nocode";


  constructor(private router: ActivatedRoute, private auth: SpotifyauthService) { }

  ngOnInit(): void {
    this.loginUrl = this.auth.getUrl(redirectUri);
    this.hideButton();
    this.router.queryParams.subscribe(params => {
      console.log(params);

      this.code = params.code;



    });
    console.log(this.code != null)
    if (this.code != null) {
      this.showSignIn = false;
      this.auth.fetchToken(this.code, redirectUri);


    }
    else {
      this.showSignIn = true;
    }
  }

  getPopularityIndex() {
    this.songs = this.auth.getPopularityIndex(this.songsIds)

  }




  hideButton() {
    this.showSignIn = false;
  }

}


