import { SpotifyauthService } from './../spotifyauth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';


var Spotify = require('spotify-web-api-js');


var s = new Spotify;


interface UserPlaylists {
  name: String;
  id: String;

}

@Component({
  selector: 'app-presavecomponent',
  templateUrl: './presavecomponent.component.html',
  styleUrls: ['./presavecomponent.component.scss'],
  providers: [SpotifyauthService]
})

export class PresavecomponentComponent implements OnInit {

  //public userPlaylists: UserPlaylists[] = this.auth.getUsersPlaylists();
  public showItems: String[] = [];
  public showSignIn: Boolean = true;
  public showform: Boolean = false;
  public showArtists: Boolean = true;
  public loginURL: String = "";
  public access_token = "";
  public code = "";



  constructor(private router: ActivatedRoute, private client: HttpClient, private auth: SpotifyauthService) { }


  ngOnInit() {

    this.loginURL = this.auth.getUrl("https://onelastglance.com/presave");



    this.router.queryParams.subscribe(params => {
      console.log(params);


      if (params.code != null) {
        this.showSignIn = !this.showSignIn;

        console.log("token: " + localStorage.getItem('access_token'));
        console.log("tokentyp: " + typeof localStorage.getItem('access_token'));


        console.log("let's fetch")
        this.code = params.code;



        if (localStorage.getItem('access_token') == 'undefined') {
          this.auth.fetchToken(this.code, "https://localhost:4200/presave/");

        }


      }


    });
    this.showArtists = true;



  }





  getRecentlyPlayed() {
    this.auth.getUsersPlaylists();

    s.setAccessToken(localStorage.getItem('access_token') || '{}');

    console.log('Token:' + localStorage.getItem('access_token'));


    s.getMyRecentlyPlayedTracks((err: any, data: any) => {
      if (err) console.error(err);

      else {
        console.log(data.items[0].track.name)

        for (var i = 0; i < data.items.length; i++) {
          this.showItems[i] = data.items[i].track.name
          console.log(this.showItems[i])
        }
      }
    }

    )


  }

  getMyTopTracks() {
    //this.auth.refreshToken(refresh_token);

    s.setAccessToken(localStorage.getItem('access_token') || '{}');

    //s.setAccessToken(localStorage.getItem('access_token') || '{}');
    this.showArtists = true;

    s.getMyTopTracks((err: any, data: any) => {
      if (err) console.error(err);

      else {
        console.log(data)

        for (var i = 0; i < data.items.length; i++) {
          this.showItems[i] = data.items[i].name
          console.log(this.showItems[i])
        }
      }
    }

    )


  }

  getMyTopArtists() {
    s.setAccessToken(localStorage.getItem('access_token') || '{}');

    //s.setAccessToken(localStorage.getItem('access_token') || '{}');

    this.showArtists = false;

    s.getMyTopArtists((err: any, data: any) => {
      if (err) console.error(err);

      else {
        console.log(data)

        for (var i = 0; i < data.items.length; i++) {
          this.showItems[i] = data.items[i].name
          console.log(this.showItems[i])
        }
      }
    }

    )


  }





}





