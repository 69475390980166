import {Component, OnInit} from '@angular/core';
import { fade } from '../animation';


@Component({
  selector: 'app-kramladen-show',
  templateUrl: './kramladen-show.component.html',
  styleUrls: ['./kramladen-show.component.scss'],
  animations: [fade]
})
export class KramladenShowComponent implements OnInit {

  photo: string = './../../assets/bandpic.jpg';
  photoMobile: string = './../../assets/bandpic_squared.jpg';
  title: string = '';

  transferClicked:boolean = false;

  price: number = 0;
  numberOfTickets: number = 0;


  constructor() {
  }

  ngOnInit(): void {

  }

  clickTransfer() {
    this.transferClicked = true;
  }

  unclickTransfer(){
    this.transferClicked = false;
  }

  calcPrice() {
    this.price = this.numberOfTickets * 8;
  }

}
