<app-header [title]='title' [photo]='photo'></app-header>

<div class="wrapper">

    <h1 class="title is-1"> STUDIO:</h1>
    <div class="content">

        <app-photo class="photos" *ngFor="let q of imagesStudio, index as p" [image]='imagesStudio[p]'
            [copyright]="private" [link]='imagesStudio[p]'></app-photo>

    </div>
    <h1 class="title is-1"> PROFESSIONAL SHOTS:</h1>

    <div class="content">

        <app-photo class="photos" *ngFor="let x of images, index as i" [image]='images[i]' [copyright]='copyright[i]'
            [link]='images[i]'></app-photo>

    </div>

    <h1 class="title is-1"> BANDVACATION 2020:</h1>
    <div class="content">

        <app-photo class="photos" *ngFor="let q of imagesVacation, index as p" [image]='imagesVacation[p]'
            [copyright]="private" [link]='imagesVacation[p]'></app-photo>

    </div>



</div>

<app-bottom></app-bottom>