<app-header [photo]="photo" [title]="title"></app-header>

<div class="wrapper">
    <h1 class="title is-1"> FIND US ON THE MOST IMPORTANT PLATFORMS:</h1>
    <div class="buttons">



        <a target="_blank" href="https://open.spotify.com/artist/5A3zjBmNmFCXZbodflKczR?si=8JwyklOIQieqs-fQFGmXJQ&nd=1">
            <button class="button is-large is-outlined is-bold">
                <span class="icon">
                    <i class="fa fa-spotify fa-5x"></i>
                </span>
            </button>
        </a>
        <a target="_blank" href="https://music.apple.com/us/artist/one-last-glance/1435655959">
            <button class="button is-large is-outlined">
                <span class="icon">
                    <i class="fa fa-apple fa-5x"></i>
                </span>
            </button>
        </a>
        <a target="_blank" href="https://www.youtube.com/onelastglance">
            <button class="button is-large is-outlined">
                <span class="icon">
                    <i class="fa fa-youtube fa-5x"></i>
                </span>
            </button>
        </a>
        <a target="_blank"
            href="https://music.amazon.de/artists/B07H63WR82?refMarker=dm_wcp_af_r&ref=dm_sh_A8CZ8HoAJdphy468IfJRcaVVy&">
            <button class="button is-large is-outlined">
                <span class="icon">
                    <i class="fa fa-amazon fa-5x"></i>
                </span>
            </button>
        </a>
        <a target="_blank" href="https://www.google.com/search?q=google+play+music+one+last+glance&sxsrf=ALeKk02zDnlK8bBt36weS_Qk8WdymckSFg%3A1616398747
        016&ei=m0lYYNE1jMJS9Pu_sAE&oq=google+play+music+one+last+glance&gs_lcp=Cgdnd3Mtd2l6EAMyBwghEAoQ
        oAE6BwgjELADECc6BwgAEEcQsAM6BwgAELADEEM6BAgAEEM6AggAOgUIABDLAToGCAAQFhAeOgcIABAKEMsBOggIIRAWEB0QHjoFCCEQoAFQ6BJY0iFgwiJoAXACeACAAZUDiAHgFJIBCjAuMTAuMi4xLjGYAQC
        gAQGqAQdnd3Mtd2l6yAEKwAEB&sclient=gws-wiz&ved=0ahUKEwiR74XJssPvAhUMoRQKHfT9DxYQ4dUDCA0&uact=5">
            <button class="button is-large is-outlined">
                <span class="icon">
                    <i class="fa fa-google fa-5x"></i>
                </span>
            </button>
        </a>
    </div>
    <h1 class="title is-1"> RELEASES:</h1>
    <div class="buttons">

        <app-photo class="releases" *ngFor="let x of artworks, index as i" [image]='artworks[i]'
            [copyright]='copyright[i]' [link]='link[i]'></app-photo>
    </div>
</div>
<app-bottom></app-bottom>