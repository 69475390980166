import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-take-it-all',
  templateUrl: './take-it-all.component.html',
  styleUrls: ['./take-it-all.component.scss']
})
export class TakeItAllComponent implements OnInit {

  title: string = '';
  name: string = 'TAKE IT ALL';

  photo: string = './../../assets/take-it-all-min.jpg';
  spotifyURL: string = 'https://open.spotify.com/track/7yDbKSKle6OwUEUOVl0J6b';
  youtubeURL: string = 'https://youtu.be/r18TRIChfUQ';
  applemusicURL: string = 'https://music.apple.com/us/album/take-it-all-single/1608948885';
  amazonmusicURL: string = 'https://music.amazon.de/albums/B09RYSLLLV?marketplaceId=A1PA6795UKMFR9&musicTerritory=DE&ref=dm_sh_gSRHBOiA3itWJcyrMs4FUISQ5';
  spotifyid: string = '7yDbKSKle6OwUEUOVl0J6b';

  constructor() { }

  ngOnInit(): void {
  }

}
