<div class="wrapper">
    <div class="buttons">

        <a href={{loginUrl}}>
            <button *ngIf="showSignIn" class="button">SIGN IN WITH SPOTIFY</button>
        </a>

        <button class="button" *ngIf="!showSignIn" (click)='getPopularityIndex()'> SHOW ME SOME DATA</button>


    </div>
    <p class="title" *ngFor="let item of songs; let i = index">
        {{item}}
    </p>



</div>