import { Title } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {

  constructor(private sideTitle: Title) { }


  ngOnInit(): void {

    this.sideTitle.setTitle('One Last Glance - Privacy');
  }

  title:string = "PRIVACY";
  photo:string = "./../../assets/snare1d.jpg";
}
