import { HttpClientModule, HttpClient } from '@angular/common/http';

import { LandingComponent } from './landing/landing.component';
import { NgModule, Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AboutComponent } from './about/about.component';
import { HeaderComponent } from './header/header.component';
import { BottomComponent } from './bottom/bottom.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';

import { NavbarComponent } from './navbar/navbar.component';
import { ShowsComponent } from './shows/shows.component';
import { MusicComponent } from './music/music.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BookingComponent } from './booking/booking.component';
import { PhotoComponent } from './photo/photo.component';
import { GalleryComponent } from './gallery/gallery.component';
import { ImprintComponent } from './imprint/imprint.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { SongComponent } from './song/song.component';
import { MindunpluggedComponent } from './mindunplugged/mindunplugged.component';
import { PresavecomponentComponent } from './presavecomponent/presavecomponent.component';
import { DontleavemeComponent } from './dontleaveme/dontleaveme.component';
import { PopularityComponent } from './popularity/popularity.component';

import { GasolineComponent } from './gasoline/gasoline.component';
import { SavesongComponent } from './savesong/savesong.component';
import { EarthComponent } from './earth/earth.component';
import { TakeItAllComponent } from './take-it-all/take-it-all.component';
import { KramladenShowComponent } from './kramladen-show/kramladen-show.component'
import {BleibInDerSchule} from './bleib-in-der-schule/bleib-in-der-schule';
import { PbhComponent } from './pbh/pbh.component';
import {CommonModule} from "@angular/common";
import {AngularFireModule} from "@angular/fire/compat";


@NgModule({
  declarations: [PhotoComponent,
    AppComponent, LandingComponent, AboutComponent, HeaderComponent, BottomComponent, NavbarComponent, ShowsComponent, MusicComponent, BookingComponent, PhotoComponent, GalleryComponent, ImprintComponent, PrivacyComponent, SongComponent, MindunpluggedComponent,
    PresavecomponentComponent, DontleavemeComponent, PopularityComponent, GasolineComponent, SavesongComponent, EarthComponent, TakeItAllComponent, KramladenShowComponent, BleibInDerSchule, PbhComponent,
  ],
  imports: [AngularFireModule,
    BrowserModule,
    AppRoutingModule, FormsModule, BrowserAnimationsModule, LayoutModule,
    FontAwesomeModule, HttpClientModule, CommonModule],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
