import {SavesongComponent} from './savesong/savesong.component';
import {GasolineComponent} from './gasoline/gasoline.component';
import {PopularityComponent} from './popularity/popularity.component';
import {DontleavemeComponent} from './dontleaveme/dontleaveme.component';
import {PresavecomponentComponent} from './presavecomponent/presavecomponent.component';
import {MindunpluggedComponent} from './mindunplugged/mindunplugged.component';
import {PrivacyComponent} from './privacy/privacy.component';
import {ImprintComponent} from './imprint/imprint.component';
import {GalleryComponent} from './gallery/gallery.component';
import {BookingComponent} from './booking/booking.component';
import {MusicComponent} from './music/music.component';
import {ShowsComponent} from './shows/shows.component';
import {AppComponent} from './app.component';
import {AboutComponent} from './about/about.component';
import {LandingComponent} from './landing/landing.component';

import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {EarthComponent} from './earth/earth.component';
import {TakeItAllComponent} from './take-it-all/take-it-all.component';
import {KramladenShowComponent} from './kramladen-show/kramladen-show.component';
import {BleibInDerSchule} from './bleib-in-der-schule/bleib-in-der-schule';
import {PbhComponent} from './pbh/pbh.component';

const routes: Routes = [{path: '', component: LandingComponent}, {path: 'component', component: AppComponent},
  {path: 'about', component: AboutComponent},
  {path: 'shows', component: ShowsComponent},
  {path: 'music', component: MusicComponent}, {path: 'booking', component: BookingComponent},
  {path: 'gallery', component: GalleryComponent},
  {path: 'imprint', component: ImprintComponent}, {path: 'privacy', component: PrivacyComponent},
  {path: 'mindunplugged', component: MindunpluggedComponent},
  {path: 'presave', component: PresavecomponentComponent},
  {path: 'dontleaveme', component: DontleavemeComponent},
  {path: 'popularity', component: PopularityComponent},
  {path: 'gasoline', component: GasolineComponent},
  {path: 'save', component: SavesongComponent},
  {path: 'earth', component: EarthComponent},
  {path: 'takeitall', component: TakeItAllComponent},
  {path: 'bleibinderschule', component: BleibInDerSchule},
  {path: 'pbh', component: PbhComponent}
  //{path: 'kramladen', component: KramladenShowComponent}
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
