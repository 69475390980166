<div class="booking">
    <app-header [photo]="photo" [title]="title"></app-header>
    <div class="wrapper">
        <h1 class="title is-1"> FEEL FREE TO CONTACT US ANY TIME</h1>
        <h1 class="title is-2"> IF YOU WANT TO TELL US SOMETHING:</h1>
        <p class="subtitle is-3">
            office@onelastglance.com
        </p>

        <h1 class="title is-2"> IF YOU WANT TO BOOK US FOR YOUR EVENT:</h1>

        <p class="subtitle is-3">
            booking@onelastglance.com
        </p>
    </div>

    <app-bottom></app-bottom>

</div>
