import { Component, OnInit, Input } from '@angular/core';
import { fade } from '../animation';
import { SpotifyauthService } from '../spotifyauth.service';
var Spotify = require('spotify-web-api-js');

var s = new Spotify;

@Component({
  selector: 'app-song',
  templateUrl: './song.component.html',
  styleUrls: ['./song.component.scss'],
  animations: [fade]
})
export class SongComponent implements OnInit {
  @Input() spotifyURL!: string;
  @Input() applemusicURL!: string;
  @Input() amazonmusicURL!: string;
  @Input() youtubeURL!: string;
  @Input() artwork!: string;
  @Input() name!: string;
  @Input() spotifyid!: string;
  @Input() teaserURL!: string;
  @Input() teaserPath: string = "";


   energy: any;
   dancability: any;
   length: any;
   tempo: any;
   loginURL: any;

  constructor(private auth: SpotifyauthService) { }
  show: Boolean = true;

  ngOnInit(): void {


    this.auth.getSong(this.spotifyid).subscribe(song => {
      this.energy = song.energy * 100 | 0;
      this.dancability = song.danceability * 100 | 0;
      this.length = this.toMin(song.duration_ms);
      this.tempo = song.tempo | 0;
    })
  }

  toMin(ms: number) {
    var min = ((ms / 1000) / 60) | 0;
    var sec = (ms / 1000) / 60 % 1 * 60 | 0;
    return min + ":" + sec + " min"
  }

}
