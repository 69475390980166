<app-header [title]='title' [photo]='photo'></app-header>

<div class="wrapper">

    <h1 class="title is-1">DATENSCHUTZERKLÄRUNG</h1>

    <p class=" subtitle is-4">Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Inc. („Google").
        Google Analytics
        verwendet sog. „Cookies", Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der
        Benutzung der Website durch Sie ermöglichen. Die durch den Cookie erzeugten Informationen über Ihre Benutzung
        dieser Website (einschließlich Ihrer IP-Adresse) wird an einen Server von Google in den USA übertragen und dort
        gespeichert. Google wird diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über
        die Websiteaktivitäten für die Websitebetreiber zusammenzustellen und um weitere mit der Websitenutzung und der
        Internetnutzung verbundene Dienstleistungen zu erbringen. Auch wird Google diese Informationen gegebenenfalls an
        Dritte übertragen, sofern dies gesetzlich vorgeschrieben oder soweit Dritte diese Daten im Auftrag von Google
        verarbeiten. Google wird in keinem Fall Ihre IP-Adresse mit anderen Daten von Google in Verbindung bringen. Sie
        können die Installation der Cookies durch eine entsprechende Einstellung Ihrer Browser Software verhindern; wir
        weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website
        vollumfänglich nutzen können.
        Durch die Nutzung dieser Website erklären Sie sich mit der Bearbeitung der über Sie erhobenen Daten durch Google
        in der zuvor beschriebenen Art und Weise und zu dem zuvor benannten Zweck einverstanden. Der Datenerhebung und
        -speicherung kann jederzeit mit Wirkung für die Zukunft widersprochen werden. Angesichts der Diskussion um den
        Einsatz von Analysetools mit vollständigen IP-Adressen möchten wir darauf hinweisen, dass diese Website Google
        Analytics mit der Erweiterung „_anonymizeIp()" verwendet und daher IP-Adressen nur gekürzt weiterverarbeitet
        werden, um eine direkte Personenbeziehbarkeit auszuschließen.
        Andere Technologien, einschließlich der von uns auf deinem Webbrowser oder Gerät gespeicherten Daten, der mit
        deinem Gerät verknüpften Kennungen sowie sonstiger Software, wie beispielsweise Facebook-Pixel, werden für
        ähnliche Zwecke genutzt.
        Des weiteren werden bei der Verwendung des Spotify-Save Services userbezogene Daten auf unbestimmte Zeit
        gespeichert!
    </p>

    <h1 class="title is-1">NEWSLETTER-ABONNEMENT</h1>

    <p class="subtitle is-4">Der Websitebetreiber bietet ihnen einen Newsletter an, in dem er Sie über aktuelle
        Geschehnisse und Angebote informiert. Möchten sie den Newsletter abonnieren, müssen Sie eine valide
        E-Mail-Adresse angeben.
        Wenn Sie sich für unseren Newsletter anmelden und uns damit Ihre Einwilligung (Art 6 Abs 1 lit a DS-GVO)
        erteilen, verarbeiten wir Ihren Namen und Ihre E-Mail-Adresse zum Zweck des Versands unseres Newsletters. Ihre
        personenbezogenen Daten werden bis zu Ihrer Abmeldung vom Newsletter bzw. dem Widerruf Ihrer Einwilligung
        gespeichert. Sie können Ihre Einwilligung jederzeit widerrufen bzw. Den Newsletter abbestellen, indem Sie eine
        Nachricht an die E-Mail-Adresse office.highfive@gmail.com schicken oder oder uns in einer anderen Form
        benachrichtigen.</p>


</div>

<app-bottom></app-bottom>