<app-header [photo]="photo" [title]="title"></app-header>

<div class="wrapper">
  <h1 class="title is-1">UPCOMING SHOWS:

  </h1>


    <h1 *ngFor="let x of upcomingShows; index as i" class="subtitle is-3" id="date"> <span *ngIf="today<x.date">
                {{x.date | date:'MMMM d, yyyy'}}
      <span class="subtitle is-3">
                    {{ x.location}}
                </span>
            </span>
    </h1>

  <hr id="endofsection">
  <h1 class="title is-1">PREVIOUS SHOWS</h1>


  <div [@listAnimation]="shows.length">

    <h1 *ngFor="let x of shows; index as i" class="subtitle is-3" id="date"> <span *ngIf="today>x.date">
                {{x.date | date:'MMMM d, yyyy'}}
      <span class="subtitle is-3">
                    {{ x.location}}
                </span>
            </span>
    </h1>
  </div>
</div>

<app-bottom></app-bottom>
