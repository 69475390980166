import {SpotifyauthService} from './../spotifyauth.service';
import {Title} from '@angular/platform-browser';
import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-music',
  templateUrl: './music.component.html',
  styleUrls: ['./music.component.scss']
})
export class MusicComponent implements OnInit {


  constructor(private sideTitle: Title, private testAuth: SpotifyauthService) {
  }

  ngOnInit(): void {

    this.sideTitle.setTitle('One Last Glance - Music');
  }

  title: string = 'MUSIC';
  photo: string = './../../assets/snare1b.jpg';
  artworks: string[] = ['./../../assets/pbh-min.jpg','./../../assets/bleib-in-der-schule.png','./../../assets/take-it-all-min.jpg','./../../assets/earth-min.jpg','./../../assets/gasoline.jpg', './../../assets/dontleaveme.jpg', './../../assets/mindunplugged.jpg', './../../assets/faint.jpg', './../../assets/hookedonyou.jpg',
    './../../assets/proudtobemyself.jpg', './../../assets/slavetosubstance.jpg', './../../assets/lilbitch.jpg',
    './../../assets/highfive.jpg'];

  copyright: string[] = ['copyright: topcreativemind','copyright: topcreativemind','copyright: topcreativemind','copyright: topcreativemind', 'copyright: topcreativemind', 'copyright: topcreativemind','copyright: topcreativemind', 'copyright: topcreativemind', 'copyright: topcreativemind', 'copyright: topcreativemind', 'copyright: topcreativemind',
    'copyright: topcreativemind', 'copyright: daveydesign', 'copyright: daveydesign', 'copyright: Sophie Höller',];

  link: string[] = ["https://www.onelastglance.com/pbh","https://www.onelastglance.com/bleibinderschule","https://www.onelastglance.com/takeitall","https://www.onelastglance.com/earth","https://www.onelastglance.com/gasoline",'https://www.onelastglance.com/dontleaveme', 'https://www.onelastglance.com/mindunplugged', 'https://www.onelastglance.com/faint', 'onelastglance.com/hookedonyou', 'onelastglance.com/proudtobemyself', '', '', ''];
}
