import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-earth',
  templateUrl: './earth.component.html',
  styleUrls: ['./earth.component.scss']
})
export class EarthComponent implements OnInit {

  constructor(private sideTitle: Title) {
  }

  ngOnInit(): void {

    this.sideTitle.setTitle('One Last Glance - Earth');
  }

  title: string = '';
  name: string = 'EARTH';

  photo: string = './../../assets/earth-min.jpg';
  spotifyURL: string = 'https://open.spotify.com/track/5WJOPTxnF9U0pbW6JAFezY?si=370b7a3929254053';
  youtubeURL: string = 'https://youtu.be/Petr6vMDA24';
  applemusicURL: string = 'https://music.apple.com/at/album/earth-single/1588903570';
  amazonmusicURL: string = 'https://music.amazon.de/albums/B09HSLRJS3?marketplaceId=A1PA6795UKMFR9&musicTerritory=DE&ref=dm_sh_wT9CPI36yY3uNNzp2Ze5z4yEZ';
  spotifyid: string = '5WJOPTxnF9U0pbW6JAFezY';
}
