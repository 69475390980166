import { slide } from './../animation';
import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';



@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [slide


  ]
})
export class HeaderComponent implements OnInit {


  @Input() title!: string;
  @Input() photo!: string;
  constructor() { }

  ngOnInit(): void {
  }

}
