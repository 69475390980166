<div class="wrapper">
    <div class="buttons">

        <a href={{loginURL}}>
            <button *ngIf="showSignIn" class="button">SIGN IN WITH SPOTIFY</button>
        </a>

        <button class="button" *ngIf="!showSignIn" (click)='getRecentlyPlayed()'> MY RECENTLY PLAYED</button>
        <button class="button" *ngIf="!showSignIn" (click)='getMyTopArtists()'> MY TOP ARTISTS</button>
        <button class="button" *ngIf="!showSignIn" (click)='getMyTopTracks()'> MY TOP TRACKS</button>
        <button class="button" *ngIf="!showSignIn" (click)='getMyTopTracks()'> MY PLAYLISTS</button>

    </div>
    <p class="title" *ngFor="let item of showItems; let i = index">
        {{item}}
    </p>







</div>