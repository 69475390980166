<nav class="navbar" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
        <a href="..//">
            <img id="image" src="./../../assets/icon.png">

        </a>

        <a role="button" [ngClass]="status ? 'navbar-burger' : 'navbar-burger is-active'" (click)="changeClass()"
            aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
        </a>
    </div>

    <div [ngClass]="status ? 'navbar-menu' : 'navbar-menu is-active'" id="navbarBasicExample">
        <div class="navbar-start">
            <a class="navbar-item" href="../about/">
                ABOUT
            </a>

            <a class="navbar-item" href="../shows/">
                SHOWS
            </a>
            <a class="navbar-item" href="../music">
                MUSIC
            </a>

            <a class="navbar-item" href="../gallery">
                GALLERY
            </a>


            <a class="navbar-item" href="../booking/">
                BOOKING
            </a>




        </div>


    </div>
</nav>