import { Title } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mindunplugged',
  templateUrl: './mindunplugged.component.html',
  styleUrls: ['./mindunplugged.component.scss']
})
export class MindunpluggedComponent implements OnInit {

  constructor(private sideTitle: Title) { }

  ngOnInit(): void {

    this.sideTitle.setTitle('One Last Glance - Mind Unplugged');
  }

  title:string = "";
  name:string = "MIND UNPLUGGED";

  photo:string = "./../../assets/mindunplugged.jpg";
  spotifyURL:string = "https://open.spotify.com/album/1WahAL3eLfDfo78w8MaeXc";
  youtubeURL:string = "https://youtu.be/pznh5KjMJ7k";
  applemusicURL:string = "https://music.apple.com/at/album/mind-unplugged/1552701332?i=1552701526&l=en";
  amazonmusicURL:string = "https://music.amazon.de/albums/B08W57LZQ5?ref=dm_sh_4FGkL6zdt33wuSBBvpeaQ80FU&trackAsin=B08W581JX6";
  spotifyid:string = "6XryjQQehFsTEQM5hYdbUE";
}
