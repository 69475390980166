import {Component, Input, OnInit} from '@angular/core';
import {fade, blur} from './../animation';


@Component({
  selector: 'app-photo',
  templateUrl: './photo.component.html',
  styleUrls: ['./photo.component.scss'],
  animations: [fade, blur]
})
export class PhotoComponent implements OnInit {

  @Input() image?: string;
  @Input() link?: string;
  @Input() copyright?: string;

  constructor() {
  }

  ngOnInit(): void {
  }

}
